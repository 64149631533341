"use client";
import React, { useEffect, useState } from "react";
import MainLayout from "@/components/layout/mainLayout";
import BuyNowCart from "@/components/common/BuyNowCart";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
import SingleCaskCaskDetails from "@/components/singlecask/SingleCaskCaskDetails";
import SingleCaskWhiskyCollection from "@/components/singlecask/SingleCaskWhiskyCollection";
import SingleCaskWhiskyDetailsCard from "@/components/singlecask/SingleCaskWhiskyDetailsCard";
import SingleCaskFeaturedBanner from "@/components/singlecask/SingleCaskFeaturedBanner";
import SingleCaskTaste from "@/components/singlecask/SingleCaskTaste";
import SingleCaskTasteBanner from "@/components/singlecask/SingleCaskTasteBanner";
import SingleCaskBanner from "@/components/singlecask/SingleCaskBanner";
import SingleCaskAwardSlider from "@/components/singlecask/SingleCaskAwardSlider";
import SignupForFree from "@/components/landingpage/SignupForFree";
import NewCollectionBanner from "@/components/newoctavecollection/NewCollectionBanner";
import CaskDetails from "@/components/newoctavecollection/CaskDetails";
import ScoMeta from "@/components/scometa";

const SingleCask = (props: any) => {
  const { data, meta, countryCode } = props;

  const [singleCaskData, setSingleCaskData]: any = useState(null);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = useState(false);

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };
  useEffect(() => {
    if (data) {
      setSingleCaskData(data?.data?.attributes?.blocks);
    }
  }, [data]);
  const renderSingleCaskPage = () => {
    return (
      <>
        {singleCaskData && singleCaskData.length > 0
          ? singleCaskData.map((content: any, index: any) => {
              switch (content.__component) {
                case "sections.banner":
                  return (
                    <div key={index}>
                      <NewCollectionBanner
                        data={content}
                        className="small_logo_for_banner top_paddings"
                      />
                    </div>
                  );

                case "singlecask.unique-spirit":
                  return (
                    <CaskDetails key={content?.id + index} data={content} />
                  );

                case "singlecask.thirdfold":
                  return (
                    <SingleCaskTasteBanner
                      key={content?.id + index}
                      data={content}
                    />
                  );
                case "singlecask.singlecask-taste":
                  return (
                    <SingleCaskTaste key={content?.id + index} data={content} />
                  );

                case "singlecask.house-cooperage":
                  return (
                    <SingleCaskCaskDetails
                      key={content?.id + index}
                      data={content}
                      className="single_content"
                      paddingClass="padding_top_zero"
                      reverseClass="Column_reverse_div"
                    />
                  );

                case "octave.our-collections":
                  return (
                    <SingleCaskWhiskyCollection
                      key={content?.id + index}
                      data={content}
                    />
                  );

                // case "singlecask.award-winning":
                //   return (
                //     <SingleCaskAwardSlider
                //       key={content?.id + index}
                //       data={content}
                //     />
                //   );
                // case "octave.journey-whiskey":
                //   return (
                //     <SingleCaskWhiskyDetailsCard
                //       key={content?.id + index}
                //       data={content}
                //     />
                //   );
                // case "singlecask.singlecask-banner":
                //   return (
                //     <SingleCaskFeaturedBanner
                //       key={content?.id + index}
                //       data={content}
                //     />
                //   );

                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };
  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      <MainLayout checkout={checkout} toggleCartSidebar={toggleCartSidebar}>
        <div className="single-cask-collection">
          {singleCaskData && renderSingleCaskPage()}
          <SignupForFree />
        </div>
      </MainLayout>
    </>
  );
};

export default SingleCask;
