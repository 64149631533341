import Image from "next/image";
import React from "react";
import { formatImageUrl } from "../../../helper/helper";

const SingleCaskTasteBanner = (props: any) => {
  let media: any = formatImageUrl(
    props?.data?.background?.data?.attributes?.url
  );
  return (
    <section className="single_cask_taste_banner_section">
      {props?.data?.background?.data && (
        <img
          src={media}
          alt={`banner img`}
          className="bg_img"
        />
      )}

      {/* <h2>{props?.data?.title}</h2> */}
      <div className="title_of_banner">
        <h3>"Whisky Without Compromise"</h3>
      </div>
    </section>
  );
};

export default SingleCaskTasteBanner;
