import Image from "next/image";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";
import Link from "next/link";

const SingleCaskWhiskyCollection = (props: any) => {
  const [bottomCoordinates, setBottomCoordinates]: any = React.useState(null);
  const [collectionData, setCollectionData]: any = React.useState([]);
  let [getCollectionProducts, setCollectionProduct]: any = useState([]);

  const handleOpenModal = (id: string) => {
    if (id) {
      const idData: any = document.getElementById(id);
      const collectionData: any = document.getElementById(
        "collection-card-div"
      );
      const collectionRange: any = collectionData.getBoundingClientRect();
      const idRange: any = idData.getBoundingClientRect();
      const finalRange: number = idRange?.top - collectionRange?.top;
      if (finalRange) {
        setBottomCoordinates(finalRange + 200);
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <section className="single_cask_whisky_collection_section">
        <div className="collection_title">the collection</div>

        <div className="collection_container" id="collection-card-div">
          <div className="single_cask_collection_card_section">
            <h2>
              {
                props?.data?.collection_groups?.data[0]?.attributes
                  ?.collection_lists?.data?.length
              }{" "}
              Products
            </h2>

            <Row>
              {props?.data?.collection_groups?.data[0]?.attributes?.collection_lists?.data?.map(
                (item: any, index: number) => {
                  let cardImage: any = formatImageUrl(
                    item?.attributes?.image?.data?.attributes?.url
                  );
                  return (
                    <Col xs={6} sm={6} md={4} xl={3} key={index}>
                      <div className="collection_card">
                        <div className="collection_card_img">
                          {item?.attributes?.image?.data && (
                            <img src={cardImage} alt={`collection image`} />
                            // <Image
                            //   src={cardImage}
                            //   alt={`collection image`}
                            //   width={148}
                            //   height={559}
                            // />
                          )}
                        </div>
                        <div className="collection_card_details">
                          <div className="collection_whisky_data">
                            <h4>{item?.attributes?.brand_name}</h4>
                            <h3>{item?.attributes?.name}</h3>
                          </div>
                          <div className="collection_btn_group">
                            <Button
                              variant="light"
                              className="btn_view_range"
                              id={`view-range-button-${index}`}
                              onClick={() => {
                                setCollectionProduct(item.attributes);
                                handleOpenModal(`view-range-button-${index}`);
                                setCollectionData(
                                  item?.attributes?.collection_products?.data
                                );
                              }}
                            >
                              view range
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                }
              )}
            </Row>
          </div>
        </div>
      </section>
      {bottomCoordinates ? (
        <section
          className="single_cask_collection_popup_section"
          style={{ top: `${bottomCoordinates}px` }}
        >
          <div className="collection_container">
            <div className="single_cask_collection_popup_inner_div">
              <div className="collection_popup_header">
                <h5>{getCollectionProducts?.name} Range</h5>
                <div className="close_icon_div">
                  <Image
                    src={"/new-octave-collection/close-icon.png"}
                    alt={`close icon`}
                    width={512}
                    height={512}
                    onClick={() => {
                      setBottomCoordinates(null);
                      setCollectionData([]);
                    }}
                  />
                </div>
              </div>
              <div className="single_cask_collection_popup_body">
                <Row>
                  {collectionData
                    .slice(0, 4)
                    .map((item: any, index: number) => {
                      let cardImage: any = formatImageUrl(
                        item?.attributes?.image?.data?.attributes?.url
                      );
                      return (
                        <Col xs={6} sm={6} md={4} xl={3} key={index}>
                          <div className="collection_popup_card">
                            <div className="collection_popup_card_img">
                              {item?.attributes?.image?.data && (
                                <img src={cardImage} alt={`collection image`} />
                              )}
                            </div>
                            <div className="collection_card_popup_details">
                              <div className="collection_whisky_popup__data">
                                <h4>{item?.attributes?.name}</h4>
                                <h3>{item?.attributes?.year_title}</h3>
                                <div className="collection_btn_group">
                                  {item?.attributes?.cta?.link ? (
                                    <Link
                                      className="view_more_for_collection"
                                      href={item?.attributes?.cta?.link}
                                      target={
                                        item?.attributes?.cta
                                          ?.open_in_new_tab == true
                                          ? "_blank"
                                          : ""
                                      }
                                    >
                                      View More
                                    </Link>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default SingleCaskWhiskyCollection;
