import React from "react";
import Button from "../common/CommonButton/Button";

const SingleCaskTaste = (props: any) => {

  return (
    <section className="single_cask_taste_section">
      <div className="collection_container">
        <div className="cask_taste_details">
          <h2>{props?.data?.sub_title}</h2>
          <h3>{props?.data?.title}</h3>
          <p>{props?.data?.content1}</p>
          <p>{props?.data?.content2}</p>
          {/* {props?.data?.cta?.link && (
            <Button
              btnText={props?.data?.cta?.title}
              btnClass={"btn_Explore"}
              btnLink={props?.data?.cta?.link}
              linkOpenState={props?.data?.cta?.open_in_new_tab == true ? "_blank" : ""}
            />
          )} */}
        </div>
      </div>
    </section>
  );
};

export default SingleCaskTaste;
