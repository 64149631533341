import Image from "next/image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";

const CaskDetails = (props: any) => {
  let caskImage: any = formatImageUrl(
    props?.data?.media?.data?.attributes?.url
  );
  let bottle_Image: any = formatImageUrl(
    props?.data?.bottle_image?.data?.attributes?.url
  );

  return (
    <section className="cask_collection_details">
      <div className="collection_container">
        <h4>{props?.data?.sub_title}</h4>
        <Row>
          <Col sm={12} md={12} lg={6}>
            <div className="cask_details">
              <div className="inner_div">
                <h2 className="for_desktop">{props?.data?.sub_title}</h2>
                <p>{props?.data?.content1}</p>
                <p>{props?.data?.content2}</p>
                <h3 className="desktop-text">{props?.data?.content3}</h3>
              </div>
              <div className="cask_mobile_img">
                {props?.data?.bottle_image?.data && (
                  <img
                    src={bottle_Image}
                    alt={`octave logo`}
                    width={336}
                    height={679}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <div className="cask_collection_detail_img">
              {props?.data?.media?.data && (
                <img
                  src={caskImage}
                  alt={`cask img`}
                  width={712}
                  height={925}
                />
              )}

              <h3 className="mobile-text">{props?.data?.content3}</h3>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CaskDetails;
