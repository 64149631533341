import Image from "next/image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";

import Button from "../common/CommonButton/Button";
import BannerVideo from "../common/video";

const SingleCaskCaskDetails = (props: any) => {
  const { className } = props;
  let media: any = formatImageUrl(props?.data?.media?.data?.attributes?.url);
  return (
    <section
      className={`single_cask_collection_details ${props?.paddingClass}`}
    >
      <div className="collection_container">
        <Row
          className={`${
            props?.reverseClass ? props?.reverseClass : "reverse_div"
          }`}
        >
          <Col sm={12} md={12} lg={6}>
            <div
              className={`single_cask_details ${className ? className : ""}`}
            >
              <div className="inner_div">
                <h4>{props?.data?.sub_title}</h4>
                <h2>{props?.data?.title}</h2>

                <p>{props?.data?.content1}</p>
                <p>{props?.data?.content2}</p>
                {/* {props?.data?.cta?.link && (
                  <Button
                    btnText={props?.data?.cta?.title}
                    btnClass={"btn_Explore_the_range"}
                    btnLink={props?.data?.cta?.link}
                    linkOpenState={
                      props?.data?.cta?.open_in_new_tab == true ? "_blank" : ""
                    }
                  />
                )} */}

                {/* <h3 className='desktop-text'>The Octave Collection is extensive, with offerings for all tastes.</h3> */}
              </div>
              <div className="cask_mobile_img">
                {props?.data?.media?.data && (
                  <img
                    src={media}
                    alt={`octave logo`}
                    width={336}
                    height={679}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <div className="single_cask_collection_detail_img">
              <BannerVideo srcURL={props?.data?.video_link} data={"content"} />

              {/* {props?.data?.media?.data && (
                <img
                  src={media}
                  alt={`single cask img`}
                  width={701}
                  height={699}
                />
              )} */}

              {/* <h3 className='mobile-text'>The Octave Collection is extensive, with offerings for all tastes.</h3> */}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default SingleCaskCaskDetails;
